<template>
  <div>
    <v-breadcrumbs :items="crumbs"></v-breadcrumbs>
    <base-loader :isLoading="isLoading"></base-loader>
    <v-row>
      <v-col md="6">
        <v-card>
          <v-card-text>
            <h4 class="text-md-h4 primary--text mb-5">Product Details</h4>
            <v-simple-table v-if="$route.params.id != 0">
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>
                    {{ productModel.name ? productModel.name : "" }}
                  </td>
                </tr>
                <tr>
                  <td>Description</td>
                  <td>
                    <v-chip label dark>{{ productModel.description }}</v-chip>
                  </td>
                </tr>
                <tr>
                  <td>Category</td>
                  <td>
                    {{ productModel.category ? productModel.category : "" }}
                  </td>
                </tr>
                <tr>
                  <td>Created By</td>
                  <td>
                    {{ productModel.createdBy ? productModel.createdBy : "" }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>

            <v-btn
              v-if="$route.params.id == 0"
              color="primary"
              :to="`${$route.path}/edit`"
              class="mr-2"
              >Create a New Product</v-btn
            >
            <v-btn
              v-else
              color="primary"
              :to="`${$route.path}/edit`"
              class="mr-2"
              >Edit</v-btn
            >
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
export default {
  data() {
    return {
      isEdit: false,
      isLoading: false,

      me: "",
      search: null,
      crumbs: [
        {
          text: "Products",
          disabled: false,
          href: "/admin/products",
        },
        {
          text: "Product Details",
          disabled: true,
          href: `/admin/product/${this.$route.params.id}`,
        },
      ],
    };
  },
  created() {},
  apollo: {
    me: gql`
      query {
        me {
          id
        }
      }
    `,
    product: {
      query: gql`
        query Product($id: ID!) {
          product(id: $id) {
            id
            name
            description
            category
            createdBy
            createdAt
            updatedAt
          }
        }
      `,
      fetchPolicy: "network-only",
      skip() {
        return !this.$route.params.id || this.$route.params.id == 0;
      },
      variables() {
        const self = this;
        return {
          id: self.$route.params.id,
        };
      },
      watchLoading(isLoading) {
        this.isLoading = isLoading;
      },
      result(response, key) {
        this.isLoading = false;
        this.productModel.id = this.product.id;
        this.productModel.name = this.product.name;
        this.productModel.description = this.product.description;
        this.productModel.category = this.product.category;
        this.productModel.createdBy = this.product.createdBy;
      },
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
